import React, { useEffect, useState, useRef } from "react";
import ChatContext from "./ChatContext";
import { API_BASE_URL } from "../redux/config";
import { io } from "socket.io-client";
import FetchMsgSideUsers from "./FetchMsgSideUsers";
import SoundMessage from "../common/assets/sound/message.mp3";

const useFetchData = (url, setData, token = null) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {};
        if (token) {
          headers["auth-token"] = token;
        }

        const response = await fetch(url, {
          method: "GET",
          headers: {
            ...headers,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setData(data);
      } catch (error) {
        setData([]);
        // console.error(`Error fetching data from ${url}:`, error);
      }
    };

    fetchData();
  }, [url, setData, token]);
};

const ChatState = ({ children }) => {
  const [chatData, setChatData] = useState([]);
  const [frontChatData, setFrontChatData] = useState([]);
  const [myChat, setMyChat] = useState([]);
  const [chatProfile, setChatProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recomended, setRecomended] = useState([]);
  const [showLastMessage, setShowLastMessage] = useState({ msgtype: "" });
  const [guestChatData, setguestChatData] = useState([]);
  const [sideAllUsers, setSideAllUsers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [renderData, setRenderData] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [askIsHelpful, setAstIsHelpful] = useState(false);
  const [profileUserData, setProfileUserData] = useState({
    id: "",
    name: "",
    image: "",
  });
  const tokenWithQuotes = localStorage.getItem("token");
  const token = tokenWithQuotes?.replace(/^"|"$/g, "");

  const [showTalkToPerson, setShowTalkToPerson] = useState(false);

  const [onlineUsers, setOnlineUsers] = useState([]);
  const [paramPage, setParamPage] = useState(0);
  const [isTyping, setIsTyping] = useState({
    status: false,
    receiverId: 0,
    senderId: 0,
  });
  const [typingData, setTypingData] = useState({});
  const audioRef = useRef(null);
  const socket = useRef(null);
  const [adminData, setAdminData] = useState([]);
  const [chatImage, setChatImage] = useState("");

  const [mydata, setMyData] = useState({ privilege: 2 });

  useEffect(() => {
    setUserId(String(mydata.id));
  }, [mydata]);

  // Reuse fetch function for different endpoints
  useFetchData(`${API_BASE_URL}/api/chat/chatdata`, setChatData);
  useFetchData(
    `${API_BASE_URL}/api/chat/front/chatdata/parent`,
    setFrontChatData
  );
  useFetchData(`${API_BASE_URL}/api/user/admins`, setAdminData);
  useFetchData(`${API_BASE_URL}/api/user/mydata`, setMyData, token || "");

  const getRecomendedData = async (id) => {
    if (!id) return null;

    try {
      const resdata = await fetch(
        `${API_BASE_URL}/api/chat/front/chatdata/recomend/${id}`
      );
      if (!resdata.ok) throw new Error(`Error: ${resdata.statusText}`);
      const finaldata = await resdata.json();
      return finaldata;
    } catch (e) {
      console.error("Failed to fetch recommended data", e);
      return null;
    }
  };

  const getAnswerData = async (id) => {
    if (!id) return null;

    try {
      const resdata = await fetch(
        `${API_BASE_URL}/api/chat/front/chatdata/answer/${id}`
      );
      if (!resdata.ok) throw new Error(`Error: ${resdata.statusText}`);
      const finaldata = await resdata.json();
      return finaldata;
    } catch (e) {
      return [];
    }
  };

  const lastMessage = {
    id: "999999999999999999999999",
    text: "Sorry, I am not able to understand your message",
    type: "received",
    date: new Date().toISOString(),
    subData: {},
  };

  const lastMessageHello = {
    id: "999999999999999999999999",
    text: "Hello! How can I assist you today?",
    type: "received",
    date: new Date().toISOString(),
    subData: {},
  };

  const [guestChatUsers, setGuestChatUsers] = useState({
    guestName: "",
    guestId: 0,
    guestProfile: "",
    adminName: "",
    adminId: 0,
    adminProfile: "",
  });

  useEffect(() => {
    const playSound = () => {
      if (audioRef?.current) {
        audioRef.current
          .play()
          .catch((error) => console.error("Error playing audio:", error));
      }
    };

    if (!userId || userId === "null" || Number(userId) < 1) return;

    socket.current = io(API_BASE_URL, { query: { userId } });

    socket.current.on("connect", () => {});
    socket.current.on("disconnect", () => {});
    socket.current.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    socket.current.on("messageFromServer", (msg) => {
      const newMsg = {
        ...msg,
        type: String(msg.userId) === String(userId) ? "sent" : "received",
      };

      if (String(msg.userId) !== String(userId)) {
        if (newMsg?.text?.length > 0) {
          playSound();
          setguestChatData((prev) => [newMsg, ...prev]);
          const originalTitle = document.title;
          document.title = "New Message!";
          setTimeout(() => {
            document.title = originalTitle;
          }, 5000);
        }
      }
      setRenderData((p) => !p);
    });

    socket.current.on("getOnlineUsers", (users) => setOnlineUsers(users));
    socket.current.on("usertyping", (typing) => {
      setTypingData(typing);
    });

    socket.current.on("chatUpdated", async () => {
      const updatedMsgUsers = await FetchMsgSideUsers({ userId: userId });
      setSideAllUsers(updatedMsgUsers);
    });

    return () => {
      socket.current.disconnect();
    };
  }, [userId]);

  useEffect(() => {
    if (!userId || userId === "null" || Number(userId) < 1) return;
    const fetchUsers = async () => {
      const updatedMsgUsers = await FetchMsgSideUsers({ userId: userId });
      setSideAllUsers(updatedMsgUsers);
    };
    if (Number(userId) > 0) fetchUsers();
  }, [userId]);

  useEffect(() => {
    if (
      socket.current &&
      isTyping.status &&
      isTyping.senderId &&
      isTyping.receiverId
    ) {
      socket.current.emit("usertyping", isTyping);
    } else if (socket.current) {
      socket.current.emit("usertyping", {
        status: false,
        receiverId: profileUserData.id,
        senderId: localStorage.getItem("userid"),
      });
    }
  }, [
    isTyping.status,
    isTyping.senderId,
    isTyping.receiverId,
    isTyping,
    profileUserData.id,
  ]);

  return (
    <ChatContext.Provider
      value={{
        chatData,
        myChat,
        setMyChat,
        chatProfile,
        setRecomended,
        recomended,
        setIsLoading,
        isLoading,
        lastMessage,
        setShowLastMessage,
        showLastMessage,
        setChatProfile,
        frontChatData,
        getRecomendedData,
        getAnswerData,
        setguestChatData,
        guestChatData,
        guestChatUsers,
        setGuestChatUsers,
        sideAllUsers,
        setProfileUserData,
        profileUserData,
        setParamPage,
        paramPage,
        onlineUsers,
        setIsTyping,
        typingData,
        renderData,
        setRenderData,
        inputStr,
        setInputStr,
        lastMessageHello,
        adminData,
        chatImage,
        setChatImage,
        askIsHelpful,
        setAstIsHelpful,
        showTalkToPerson,
        setShowTalkToPerson,
        mydata,
      }}
    >
      {children}
      <audio ref={audioRef} src={SoundMessage} className="hidden" />
    </ChatContext.Provider>
  );
};

export default ChatState;
