import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaSyncAlt } from "react-icons/fa";
import { Avatar, Button } from "@nextui-org/react";
import { TbDevicesUp } from "react-icons/tb";
import { FaArrowRight } from "react-icons/fa6";
import axios from "axios";
import { API_BASE_URL } from "../../../../redux/config.js";

const AvatarPage = ({
  setIsChooseImage,
  setSelectedImage,
  formRegisterData,
  // sendOTP,
  handelRegister,
  loadingBtn,
}) => {
  const [avatarData, setAvatarData] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const btnref = useRef();

  // Function to shuffle array and get 5 random avatars
  const getRandomAvatars = (data) => {
    const shuffledAvatars = data.sort(() => 0.5 - Math.random());
    return shuffledAvatars.slice(0, 5);
  };

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/user/avatar`);
        setAvatarData(response.data);
        setAvatars(getRandomAvatars(response.data)); // Set the avatars after fetching
      } catch (error) {
        console.error("Error fetching avatars:", error);
      }
    };

    fetchAvatars();
  }, []);

  const handleRefresh = () => {
    setAvatars(getRandomAvatars(avatarData));
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      setSelectedFile(imageUrl);
      setSelectedImage(file); // Update the form state with the selected file
    }
  };

  const handleAvatarClick = (avatar) => {
    setSelectedFile(`${API_BASE_URL}/${avatar}`);
    setSelectedImage(avatar); // Update the form state with the selected avatar
  };

  const sendOTPWithLoading = async () => {
    if (
      formRegisterData?.email?.toLowerCase() === "superadmin@gmail.com" ||
      formRegisterData?.email?.toLowerCase() === "gaurabsunar0001@gmail.com" ||
      formRegisterData?.email?.toLowerCase() === "giriseetal@gmail.com"
    ) {
      handelRegister();
    } else {
      handelRegister();
      // setIsLoading(true);
      // await sendOTP();
      // setIsLoading(false);
    }
  };

  return (
    <div className="p-4 h-full mt-10 w-full flex justify-center gap-[1.3rem] items-center flex-col">
      <div className="flex gap-5 flex-col justify-center items-center w-full">
        <div className="mb-3 scale-125">
          <Avatar
            color="primary"
            size="lg"
            className="scale-150 cursor-pointer duration-200 hover:bg-blue-800 hover:border-black"
            src={selectedFile}
          />
        </div>
        <div>
          <div className="font-poppins capitalize text-center font-semibold">
            {formRegisterData.name}
          </div>
          <div className="font-poppins font-semibold text-sm ml-1 text-center">
            Your Profile
          </div>
        </div>
      </div>
      <div className="flex relative w-[90%] xl:w-[60%] flex-wrap flex-col border shadow rounded bg-slate-50 pt-20 p-12 px-14 ">
        <div className="absolute flex gap-2 top-4">
          <div className="font-poppins ml-[-10px] font-semibold text-gray-700">
            Choose Avatar:
          </div>
        </div>
        <div className="flex gap-14 flex-wrap justify-center">
          {avatars.map((avatar, index) => (
            <div
              className="border-2 hover:scale-110 duration-200 border-black"
              key={index}
              onClick={() => handleAvatarClick(avatar)}
            >
              <Avatar
                color="primary"
                size="lg"
                className="scale-150 cursor-pointer duration-200 hover:bg-blue-800 hover:border-black"
                src={`${API_BASE_URL}/${avatar}`}
              />
            </div>
          ))}
        </div>

        <button
          onClick={handleRefresh}
          className="p-2 bg-green-500 absolute top-[-10px] right-[-10px] text-white rounded-full hover:bg-green-600"
        >
          <FaSyncAlt className="text-xs" />
        </button>
      </div>
      <div className="flex w-full mt-[-10px] items-center gap-4">
        <div className="w-full">
          <div
            onClick={() => btnref.current.click()}
            htmlFor="avatarUpload"
            className="flex shadow gap-2 item-center border-dashed border-black/30 justify-center h-[70px] cursor-pointer xl:w-[60%] w-[90%] m-auto border rounded-sm bg-slate-50 hover:bg-blue-300 duration-200 text-gray-700 hover:text-black"
          >
            <div className="flex justify-center items-center gap-2">
              <div className="font-poppins font-semibold">
                Choose from device
              </div>
              <TbDevicesUp className="text-xl " />
            </div>
          </div>
          <input
            type="file"
            id="avatarUpload"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
            ref={btnref}
          />
        </div>
      </div>
      <div className="flex w-[90%] xl:w-[60%] gap-5">
        <Button
          startContent={<FaArrowLeft className="mt-[2px]" />}
          radius="none"
          variant="bordered"
          className="w-1/2 rounded"
          color="primary"
          size="lg"
          onClick={() => setIsChooseImage(false)}
        >
          Previous Page
        </Button>
        <Button
          endContent={<FaArrowRight className="mt-1" />}
          radius="none"
          className="w-1/2 rounded"
          color="primary"
          size="lg"
          onClick={sendOTPWithLoading}
          isDisabled={formRegisterData.image === ""}
          isLoading={loadingBtn}
        >
          {loadingBtn ? "Creating account, please wait..." : "Create Account"}
        </Button>
      </div>
    </div>
  );
};

export default AvatarPage;
