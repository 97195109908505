import React, { useContext, useEffect, useState } from "react";
import TablePage from "./Table";
import axios from "axios";
import { toast } from "sonner";
import ChatContext from "../../../../../chatContext/ChatContext";
import { API_BASE_URL } from "../../../../../redux/config";

const ChatComponent = () => {
  const [postUpload, setPostUpload] = useState(0);
  const [finalData, setFinalData] = useState([]);
  const { chatData } = useContext(ChatContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (chatData?.length > 0) {
      setData(flattenData(chatData));
    }
  }, [chatData]);

  const flattenData = (data) => {
    const result = [];

    const traverse = (node, parent = "-") => {
      if (!node || node.text === "") return;

      // Add the current node to the result
      if (node.text) {
        result.push({
          date: node.date,
          id: node.id,
          image: node.image,
          isans: node.isans,
          parentid: node.parentid,
          parentname: node.parentname,
          text: node.text,
          type: node.type,
          url: node.url,
        });
      }

      // If subData is an array, recursively traverse each sub-node
      if (Array.isArray(node.subData)) {
        node.subData.forEach((subNode) => traverse(subNode, node.text));
      }
      // If subData is an object, traverse it as a single node
      else if (node.subData && typeof node.subData === "object") {
        traverse(node.subData, node.text);
      }
    };

    // Start traversal from the top-level nodes
    data.forEach((node) => traverse(node));
    return result;
  };

  useEffect(() => {
    if (postUpload >= 100) {
      setTimeout(() => {
        setPostUpload(0);
      }, 1000);
    }
  }, [postUpload]);

  useEffect(() => {
    let finalchatData = data.map((item, index) => ({
      ...item,
      sn: index + 1,
    }));
    setFinalData(finalchatData);
  }, [data]);

  const handelPostchat = async (data) => {
    try {
      const formData = new FormData();
      formData.append("image", data.image);
      formData.append("isans", Number(data.isans));
      formData.append("url", data.msgurl);
      formData.append("parentid", data.parentid);
      formData.append("rootparentid", data.parentid);
      formData.append("text", data.message);

      const response = await axios.post(
        `${API_BASE_URL}/api/chat/chatdata`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPostUpload(percentCompleted);
          },
        }
      );

      const newChatData = response.data?.data;
      if (newChatData) {
        toast.success("Message added successfully");
        if (newChatData) {
          setData((p) => [newChatData, ...p]);
        }
      } else {
        toast.error("Cannot add message");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Some error occurred");
    }
  };

  const handelDelete = async (data) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/chat/chatdata/${data}`
      );

      if (response.status === 200) {
        toast.success("Chat data deleted successfully");
        setData((prevData) =>
          prevData.filter((item) => Number(item.id) !== Number(data))
        );
      } else {
        toast.error("Failed to delete chat data");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Some error occurred");
    }
  };

  const handelUpdate = async (data) => {
    try {
      const formData = new FormData();
      formData.append("image", data.image);
      formData.append("isans", Number(data.isans));
      formData.append("url", data.msgurl);
      formData.append("parentid", data.parentid);
      formData.append("rootparentid", data.parentid);
      formData.append("text", data.message);

      const response = await axios.put(
        `${API_BASE_URL}/api/chat/chatdata/${data.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPostUpload(percentCompleted);
          },
        }
      );

      const newChatData = response.data?.data;
      if (newChatData) {
        toast.success("Message updated successfully");
        setData((prevData) =>
          prevData.map((item) => (item.id === data.id ? newChatData : item))
        );
      } else {
        toast.error("Cannot update message");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Some error occurred");
    }
  };

  return (
    <>
      <TablePage
        chatData={finalData[0]?.sn ? finalData : []}
        handelPostchat={handelPostchat}
        handelDelete={handelDelete}
        handelUpdate={handelUpdate}
        postUpload={postUpload}
        ParentData={data}
      />
    </>
  );
};

export default ChatComponent;
