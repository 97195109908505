import React from "react";
import { Badge, Avatar } from "@nextui-org/react";
import { Link, useParams } from "react-router-dom";
// import { IoCheckmarkDoneSharp, IoCheckmarkSharp } from "react-icons/io5";
import TimeAgo from "../common/TimeAgo.js";
import { API_BASE_URL } from "../../../../../../redux/config.js";

const EachUser = ({ data, onlineUsers }) => {
  const isOnline = onlineUsers?.includes(String(data.chatUserId));
  const { id } = useParams();

  return (
    <Link
      to={`/dashboard/message/uid/${data.chatUserId}`}
      className={`shadowchat h-[60px] rounded-lg w-full duration-150  hover:bg-[#b2cafe] cursor-pointer ${
        Number(id) === Number(data.chatUserId) ? "bg-[#a1befc]" : "bg-white"
      } `}
    >
      <div className="h-full w-full flex justify-start items-center px-2 gap-2">
        <Badge
          content=""
          color="success"
          shape="circle"
          placement="bottom-right"
          className={`${!isOnline ? "hidden ml-1" : "block"}`}
          size="md"
        >
          <Avatar
            radius="full"
            src={
              data?.userprofile?.toLowerCase()?.includes("http")
                ? data.userprofile
                : `${API_BASE_URL}/${data.userprofile}`
            }
            size="md"
            classNames={{
              icon: "text-black/50",
            }}
            showFallback
          />
        </Badge>

        <div className="h-full w-full flex justify-start items-center relative">
          <div className="font-poppins w-full  ">
            <div className="text-black text-sm mt-[1px] font-medium flex justify-between">
              <div className="w-[70%] line-clamp-1 capitalize">
                {data.username || "Guest user"}
              </div>
              <div className=" text-black/70 text-[0.55rem] ">
                {TimeAgo(data.lastmsgDate)}
              </div>
            </div>
            {data.messageType === "sent" ? (
              <div className="-mt-[2px] ml-[2px] flex justify-start items-center line-clamp-1 text-black/70 text-[0.70rem] ">
                <div className="max-w-[90%] line-clamp-1">
                  You:{" "}
                  {data.lastmsg.length > 15
                    ? data.lastmsg.slice(0, 15) + "..."
                    : data.lastmsg || "Sent a message"}
                </div>
              </div>
            ) : (
              <div className="-mt-[2px] ml-[2px] flex justify-between items-center line-clamp-1 text-black/70 text-[0.70rem] ">
                <div className="w-[80%] overflow-hidden max-w-[160px]  line-clamp-1">
                  {data.lastmsg.length > 15
                    ? data.lastmsg.slice(0, 15) + "..."
                    : data.lastmsg || "Received a message"}
                </div>
                {Number(data.chatUserId) !== Number(id) && (
                  <>
                    {Number(data.unreadmsg) > 0 && (
                      <div className="h-[15px] w-[15px] scale-90 mt-[2px] font-poppins p-1 bg-[#7451f8] text-white rounded-full flex justify-center items-center">
                        {data.unreadmsg}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EachUser;
