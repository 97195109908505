import axios from "axios";
import { API_BASE_URL } from "../../../../../redux/config";

export const updateMessagesAsRead = async (senderId, receiverId) => {
  try {
    await axios.put(
      `${API_BASE_URL}/api/messages/updatemsg/${senderId}/${receiverId}`,
      {},
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const postMessage = async (data, adminData, setguestChatData) => {
  try {
    const receiverEmailData = adminData.find(
      (user) => String(user.id) === String(data.receiverId)
    );
    const formData = new FormData();
    formData.append("text", data.text || "");
    formData.append("receiverEmail", receiverEmailData?.email || "");
    formData.append("senderName", localStorage.getItem("username") || "");
    formData.append("receiverId", data.receiverId || "");
    formData.append("senderId", data.senderId || "");
    formData.append("replyId", data.replyId || "");
    data.image.length > 0
      ? data.image.forEach((img) => {
          formData.append("images", img);
        })
      : formData.append("images", "");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await axios.post(
      `${API_BASE_URL}/api/messages/message`,
      formData,
      config
    );
    const responseData = response?.data || [];
    setguestChatData((p) => [{ type: "sent", ...responseData }, ...p]);
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
