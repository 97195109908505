import { Button, Input, Textarea } from "@nextui-org/react";
import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { toast } from "sonner";
import { postfeedbacks } from "../../../../redux/slices/feedbackSlice";
import { useDispatch } from "react-redux";

const FeedbackModal = ({ setOpenFeedbackModal }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const dispatch = useDispatch();

  const handelpost = async (data) => {
    try {
      const response = await dispatch(postfeedbacks(data));
      if (response.payload?.success) {
        toast.success("Thank you for your feedback");
        cancleBtnClk();
      } else if (response.payload?.error) {
        toast.error(response.payload?.error);
      } else {
        toast.error("Cannot send message");
      }
    } catch (error) {
      toast.error("Some error occurred");
    }
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};

    if (!formData.name) {
      errors.name = "Name is required";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (
      formData.phoneNumber.length !== 10 ||
      !/^\d+$/.test(formData.phoneNumber)
    ) {
      errors.phoneNumber = "Phone number must be 10 digits";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    } else if (formData.message.length < 5) {
      errors.message = "Message must be at least 5 characters";
    }

    return errors;
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const confirmBtnClk = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      handelpost({
        ...formData,
        phone: formData.phoneNumber,
        msg: formData.message,
      });
    }
  };

  const cancleBtnClk = () => {
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    });
    setOpenFeedbackModal((p) => !p);
  };

  return (
    <div className="h-full w-full bg-[#000000be] absolute inset-0 z-[900] flex justify-center items-center">
      <div className="relative bg-[#f0f8ff] p-5 rounded shadow-lg w-[90%] max-w-md">
        <div onClick={cancleBtnClk} className="absolute top-3 right-3 ">
          <RxCross2 className="text-xl text-black/70 hover:scale-110 duration-150 cursor-pointer hover:text-black" />
        </div>
        <form
          className="text-center flex flex-col gap-4"
          onSubmit={confirmBtnClk}
        >
          <h2 className="font-semibold text-2xl text-[#0056A6] text-left -mb-1">
            Feedback
          </h2>
          <div
            className={`flex flex-col ${
              Object.keys(errors).length > 0 ? "gap-1" : "gap-3"
            }`}
          >
            <div>
              <Input
                variant="bordered"
                label="Your name"
                size="sm"
                required
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errors.name && (
                <p className="text-red-500 text-xs text-left ml-1">
                  {errors.name}
                </p>
              )}
            </div>
            <div>
              <Input
                variant="bordered"
                label="Your email"
                size="sm"
                required
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <p className="text-red-500 text-xs text-left ml-1">
                  {errors.email}
                </p>
              )}
            </div>
            <div>
              <Input
                variant="bordered"
                label="Your phone number"
                size="sm"
                required
                type="number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-xs text-left ml-1">
                  {errors.phoneNumber}
                </p>
              )}
            </div>
            <div>
              <Textarea
                label="Your suggestion"
                variant="bordered"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                minRows={3}
                maxRows={3}
              />
              {errors.message && (
                <p className="text-red-500 text-xs text-left ml-1">
                  {errors.message}
                </p>
              )}
            </div>
          </div>
          <div className="w-full flex gap-2 pb-2 flex-row-reverse">
            <Button
              onClick={confirmBtnClk}
              variant="solid"
              color="primary"
              size="md"
              radius="md"
              className="scale-95  w-1/2 !py-2"
              type="submit"
              endContent={
                <FaArrowRight className="scale-110 mt-[1px] -ml-0.5" />
              }
            >
              Submit
            </Button>
            <Button
              onClick={cancleBtnClk}
              variant="ghost"
              color="danger"
              size="md"
              radius="md"
              className="scale-95  w-1/2 !py-2"
              type="reset"
              endContent={
                <MdOutlineCancel className="scale-110 mt-[1px] -ml-0.5" />
              }
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackModal;
