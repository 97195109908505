import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Users from "./userList/UsersContainer";
import Chat from "./MessageContainer/Chat.js";
import NoUserSelected from "./common/NoUserSelected.js";

const Message = ({ hamClick, isdashboard }) => {
  const { id } = useParams();
  const btnClkRef = useRef(false);

  const [showChat, setShowChat] = useState(false);
  useEffect(() => {
    if (id) {
      setShowChat(true);
    } else {
      setShowChat(false);
    }
  }, [id]);

  return (
    <div
      className={`h-screen w-full absolute inset-0 ${
        !hamClick ? "pl-[180px]" : "pl-[50px]"
      } pt-[50px]`}
    >
      <div className="flex h-full web-wash">
        <div className="flex w-full overflow-y-hidden z-40 h-full">
          <div
            className={`sm:block md:!w-[370px]  ${
              showChat ? "hidden" : "block"
            }`}
          >
            <Users btnClkRef={btnClkRef} isdashboard={isdashboard} />
          </div>
          <div
            className={`sm:block h-full w-full ${
              !showChat ? "hidden" : "block"
            }`}
          >
            {Number(id) > 0 ? (
              <Chat userId={id} />
            ) : (
              <NoUserSelected btnClkRef={btnClkRef} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
