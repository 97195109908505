import React, { useRef, useState, useEffect, useContext } from "react";
import { IoSend } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import ChatContext from "../../../../../chatContext/ChatContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateMessagesAsRead, postMessage } from "./sendMsgUtils";
import { PiImageFill } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";

const SendMsgInp = ({
  setGoHome,
  setRecomended,
  setShowLastMessage,
  setMyChat,
  setIsLoading,
  goHome,
  isDashboard,
}) => {
  const textareaRef = useRef(null);
  const {
    guestChatUsers,
    setguestChatData,
    setGuestChatUsers,
    setProfileUserData,
    setIsTyping,
    profileUserData,
    sideAllUsers,
    inputStr,
    setInputStr,
    myChat,
    adminData,
    setAstIsHelpful,
    setShowTalkToPerson,
  } = useContext(ChatContext);
  const params = useParams();
  const location = useLocation().pathname;

  const [selectedImages, setSelectedImages] = useState([]);

  const prevSenderId = useRef(null);
  const prevReceiverId = useRef(null);

  useEffect(() => {
    const senderId = params?.id || localStorage.getItem("adminid");
    const receiverId = localStorage.getItem("userid");

    if (
      senderId !== prevSenderId.current ||
      receiverId !== prevReceiverId.current
    ) {
      updateMessagesAsRead(senderId, receiverId);
      prevSenderId.current = senderId;
      prevReceiverId.current = receiverId;
    }
  }, [params, sideAllUsers]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputStr]);

  const greetings = [
    "hello",
    "hi",
    "hey",
    "greetings",
    "howdy",
    "hola",
    "salutations",
  ];

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + selectedImages.length > 4) {
      alert("You can only upload up to 4 images.");
      return;
    }

    const updatedImages = [...selectedImages, ...files];
    setSelectedImages(updatedImages);
  };

  const handleImageDelete = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputStr.trim().length < 1 && selectedImages.length === 0) return;
    setLoading(true);
    const finaldata = {
      ...guestChatUsers,
      text: inputStr,
      receiverId: params?.id || localStorage.getItem("adminid"),
      senderId: localStorage.getItem("userid"),
    };
    setShowTalkToPerson(false);
    if (
      Number(localStorage.getItem("userid")) &&
      Number(finaldata?.receiverId) > 0
    ) {
      finaldata.image = selectedImages;
      await postMessage(finaldata, adminData, setguestChatData);
      setInputStr("");
      setSelectedImages([]);
      setIsTyping((p) => ({ status: false, receiverId: 0, senderId: 0 }));
    } else {
      setMyChat((p) => [
        ...p,
        {
          id: Math.random(),
          type: "sent",
          image: selectedImages,
          text: finaldata.text,
          date: new Date().toISOString(),
          subData: {},
        },
      ]);
      setIsLoading(true);
      setRecomended([]);
      setAstIsHelpful(false);
      if (!goHome) {
        setGoHome(true);
      }
      setTimeout(() => {
        const trimmedInput = inputStr.trim().toLowerCase();
        if (greetings.includes(trimmedInput) || myChat.length === 0) {
          setShowLastMessage({ msgtype: "lastmsghello" });
        } else {
          setShowLastMessage({ msgtype: "lastmsg" });
        }
        setIsLoading(false);
      }, 1001);
      setIsTyping((p) => ({ status: false, receiverId: 0, senderId: 0 }));
      setInputStr("");
      setSelectedImages([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    const userId = Number(localStorage.getItem("userid"));
    const adminId = Number(localStorage.getItem("adminid"));

    if (userId > 0 && adminId > 0) {
      setGoHome(true);
    }
  }, [setGoHome]);
  const navigate = useNavigate();
  const homeBtnClick = () => {
    if (location.toLowerCase().includes("/dashboard")) {
      navigate("/dashboard/message");
    } else {
      localStorage.removeItem("userid");
      localStorage.removeItem("adminid");
      setGuestChatUsers([]);
      setGoHome(false);
      setRecomended([]);
      setAstIsHelpful(false);
      setProfileUserData({});
      setShowLastMessage({ msgtype: "" });
      setShowTalkToPerson(false);
    }
  };

  const handleChangeMsg = (e) => {
    setInputStr(e.target.value);
    setShowLastMessage({ msgtype: "" });

    if (e.target.value.length > 0) {
      setIsTyping((p) => ({
        ...p,
        status: true,
        receiverId: profileUserData.id,
        senderId: localStorage.getItem("userid"),
      }));
    } else {
      setIsTyping((p) => ({ status: false, receiverId: 0, senderId: 0 }));
    }
  };

  const imageclick = useRef(null);
  const handleImageClick = () => {
    if (imageclick.current) {
      imageclick.current.click(); // Trigger the click on the hidden input
    }
  };

  return (
    <div
      className={`absolute ${
        isDashboard
          ? "bg-[#f0f8ff]"
          : " bg-gradient-to-b to-[#2B0282] from-[#0056A6]"
      } h-[65px] flex justify-center items-center z-50 right-0 left-0 web-wash border-t !bottom-0`}
    >
      <form className="w-full relative " onSubmit={handleSubmit}>
        {selectedImages.length > 0 && (
          <div className="h-[60px] w-full px-6 absolute gap-4 inset-0 top-[-74px] bg-slate-200 flex justify-start items-center overflow-x-auto">
            {selectedImages.map((image, index) => (
              <div key={index} className="relative group">
                <div className="bg-blue-100 rounded flex justify-center items-center overflow-hidden">
                  <img
                    src={URL.createObjectURL(image)}
                    className="h-[42px] w-[42px] object-cover rounded-sm"
                    alt={`Selected ${index + 1}`}
                  />
                </div>

                <RxCross2
                  className="absolute top-[-5px] right-[-5px] h-[16px] hidden group-hover:block w-[16px] p-[1px] bg-blue-700 text-white rounded-full cursor-pointer hover:bg-blue-800 hover:scale-105"
                  onClick={() => handleImageDelete(index)}
                />
              </div>
            ))}
          </div>
        )}

        <div
          className={`w-full flex justify-center items-center gap-3 px-2 sm:px-5`}
        >
          <FaHome
            onClick={homeBtnClick}
            className="text-2xl text-[#0056A6] hover:text-[#2B0282] duration-200 hover:scale-110 cursor-pointer absolute left-[1.3rem] bottom-[0.4rem] sm:left-9 z-40"
          />

          <div className="relative w-full z-20 h-full flex justify-center items-center">
            <input
              ref={textareaRef}
              type="text"
              name="message"
              id="message"
              placeholder="Your message..."
              className={` w-full flex justify-center items-center border rounded-full border-black/15 !bg-[#fbfcf8] px-12 ${
                Number(localStorage.getItem("userid")) > 0 ? "pr-10" : "pr-4"
              }  !h-[35px] font-poppins text-[0.85rem] focus:outline-none text-sm focus:border-[#009dbd75] !text-gray-700`}
              value={inputStr}
              onChange={handleChangeMsg}
              autoComplete="off"
            />
            {Number(localStorage.getItem("userid")) > 0 && (
              <div className="absolute right-2.5">
                <PiImageFill
                  onClick={handleImageClick}
                  className="text-2xl text-[#0056A6] hover:text-[#2B0282] duration-200 hover:scale-110 cursor-pointer z-40"
                />

                <input
                  type="file"
                  name="image"
                  ref={imageclick}
                  className="hidden"
                  id="imageInput"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={handleImageChange}
                  multiple
                />
              </div>
            )}
          </div>

          <button type="submit" disabled={loading} className="outline-none">
            <IoSend
              className={`text-2xl scale-105 cursor-pointer ${
                isDashboard ? "text-[#2B0282]" : "text-[#fbfcf8]"
              } hover:text-[#ff] 
    ${loading ? "text-[#b395f2]" : "text-[#2B0282]"}
    `}
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default SendMsgInp;
