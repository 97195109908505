import { Button } from "@nextui-org/react";
import React, { useContext } from "react";
import { API_BASE_URL } from "../../../../redux/config";
import ChatContext from "../../../../chatContext/ChatContext";

const Modal = ({ setChatProfileClick, setOpenConfirmModal }) => {
  const { myChat } = useContext(ChatContext);
  const confirmBtnClk = () => {
    handleSaveChatData();
  };
  const cancleBtnClk = () => {
    setOpenConfirmModal((p) => !p);
  };

  const handleSaveChatData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/user/savechatdata`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(myChat),
      });

      await response.json();
      // const result = await response.json();
      if (response.ok) {
        setChatProfileClick(false);
      } else {
      }
    } catch (error) {}
  };
  return (
    <div className="h-full w-full bg-[#00000086] absolute inset-0 z-[900] flex justify-center items-center">
      <div className="relative bg-white p-5 rounded shadow-lg w-[80%] max-w-md">
        <div className="text-center">
          <h2 className="text-lg font-semibold mb-4">
            Are you sure you want to close this?
          </h2>
          <div className="flex justify-center space-x-4">
            <Button
              onClick={cancleBtnClk}
              variant="ghost"
              color="primary"
              size="sm"
              radius="none"
              className="rounded"
            >
              Cancel
            </Button>
            <Button
              onClick={confirmBtnClk}
              variant="solid"
              color="primary"
              size="sm"
              radius="none"
              className="rounded"
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
