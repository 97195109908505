import React from "react";
import { RxCross2 } from "react-icons/rx";

const ShowImageModal = ({ chatImage, setChatImage, isDashboard }) => {
  const cancleBtnClk = () => {
    setChatImage("");
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      cancleBtnClk();
    }
  };

  return (
    <div
      className={`h-full w-full ${
        isDashboard ? "bg-[#00000010]" : "bg-[#00000040]"
      }  absolute inset-0 z-[900] flex justify-center items-center`}
      onClick={handleOutsideClick}
    >
      <div className="relative bg-[#fff] rounded shadow-lg w-[90%] max-w-md">
        <div
          onClick={cancleBtnClk}
          className="absolute top-3 right-3 bg-blue-700 group hover:bg-blue-900 rounded-full scale-110"
        >
          <RxCross2 className="text-xl text-white/80 p-[2px]  hover:scale-110 duration-150 cursor-pointer hover:text-white" />
        </div>
        <div className="h-full w-full">
          <img
            src={chatImage}
            className={`${
              isDashboard ? "max-h-[550px]" : "max-h-[400px]"
            } min-h-[200px] min-w-[200px] w-full object-contain`}
            alt="chatimage"
          />
        </div>
      </div>
    </div>
  );
};

export default ShowImageModal;
