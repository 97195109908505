import React from "react";
import ChatComponent from "./ChatComponent";

const Addchat = () => {
  return (
    <div>
      <ChatComponent />
    </div>
  );
};

export default Addchat;
