import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import SendMsgInp from "../MessageContainer/Chat";
import axios from "axios";
import { API_BASE_URL } from "../../../../../../redux/config";

export default function RequestMessage({ btnClkRef }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [userEmail, setUserEmail] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const inputRef = useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [selectSuggestion, setSelectSuggestion] = useState(false);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus(); // Focus the input field when the modal opens
    }
  }, [isOpen]);

  useEffect(() => {
    if (userEmail.length > 3) {
      const fetchSuggestions = async () => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/user/suggestions?email=${userEmail}`
          );
          setSuggestions(response.data);
          setHighlightedIndex(-1);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      };
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [userEmail]);

  const handleSelectSuggestion = async (email) => {
    setUserEmail(email);
    setSuggestions([]);
    setSelectSuggestion(true);
    inputRef.current.blur(); // remove focus from input to close suggestions
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/user/id?email=${email}`
      );
      setSelectedUserId(response.data.id);
    } catch (error) {
      console.error("Error fetching user ID:", error);
    }
  };

  const handleInputChange = (e) => {
    setUserEmail(e.target.value);
    setSelectedUserId(null); // reset user ID when input changes
    setSelectSuggestion(false);
  };

  const handleKeyDown = (e) => {
    if (suggestions.length > 0) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
        );
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
        );
      } else if (e.key === "Enter" && highlightedIndex >= 0) {
        e.preventDefault();
        handleSelectSuggestion(suggestions[highlightedIndex].email);
      }
    }
  };

  const liClickdata = (email) => {
    handleSelectSuggestion(email);
    setSuggestions([]);
    setSelectSuggestion(true);
  };

  return (
    <>
      <Button className="!hidden" ref={btnClkRef} onPress={onOpen}>
        Open Modal
      </Button>
      <Modal
        className="!overflow-visible max-h-[200px] bottom-[40%] md:bottom-0 w-[95%] mx-auto"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Message Request
              </ModalHeader>
              <ModalBody>
                <div className="h-[110px] !mt-[-10px]">
                  <div className="relative">
                    <input
                      type="text"
                      name="message"
                      id="message"
                      placeholder="useremail@gmail.com"
                      className="linespaceminus w-[95%] sm:w-[91%] -ml-1.5 flex justify-center items-center shadow border border-gray-200 rounded-full px-5 !h-[35px] bg-slate-100 font-poppins text-[0.85rem] focus:outline-none focus:ring-1 sm:mr-0 mr-2 focus:ring-gray-300"
                      autoComplete="off"
                      value={userEmail}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      ref={inputRef}
                    />
                    {!selectSuggestion && suggestions.length > 0 && (
                      <ul className="absolute w-[91%] z-[100] bg-white border border-gray-200 rounded-lg shadow-lg mt-1">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={suggestion.email}
                            className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                              index === highlightedIndex ? "bg-gray-200" : ""
                            }`}
                            onClick={() => liClickdata(suggestion.email)}
                          >
                            {suggestion.email}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <SendMsgInp
                    selectedUserId={selectedUserId}
                    setSelectedUserId={setSelectedUserId}
                    setUserEmail={setUserEmail}
                    userEmail={userEmail}
                    replyMsg={null}
                    isrecent={true}
                    btnClkRef={btnClkRef}
                    closeModal={onOpenChange} // Pass the onOpenChange function here
                  />
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
