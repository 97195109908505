import React from "react";
import { IoReloadCircle } from "react-icons/io5";
import { FaAngleRight, FaArrowLeft } from "react-icons/fa";
import { Avatar, Button, Input } from "@nextui-org/react";
import Login from "./Login";

const UserLoginModeForm = ({
  activePanel,
  GuestUserPlaced,
  handleBackClick,
  fetchAvatar,
  rotate,
  handleProfileClick,
  guestChatUsers,
  API_BASE_URL,
  userName,
  setUser,
  error,
  navigate,
}) => {
  return (
    <>
      <form
        className="text-center flex z-20 flex-col gap-4"
        onSubmit={
          activePanel === "guest" ? GuestUserPlaced : (e) => e.preventDefault()
        }
      >
        {activePanel !== "profiles" ? (
          <h2
            onClick={handleBackClick}
            className="font-semibold flex hover:tracking-wide hover:gap-1.5 duration-100 justify-start items-center gap-1 cursor-pointer text-center text-lg text-[#0056a6] -mt-1"
          >
            <FaArrowLeft /> {activePanel === "guest" ? "Guest name" : "Login"}
          </h2>
        ) : (
          <h2 className="font-semibold flex hover:gap-1.5 duration-100 justify-center items-center gap-1 text-center text-lg text-[#0056a6]">
            Chat from
          </h2>
        )}
        <div
          className={`flex w-full duration-150 relative ${
            activePanel === "profiles"
              ? "h-[90px]"
              : activePanel === "guest"
              ? "h-[100px]"
              : "h-[170px]"
          }`}
        >
          <div
            className={`absolute transition-transform duration-500 flex w-full justify-around items-center ${
              activePanel !== "profiles"
                ? "-translate-x-full opacity-0"
                : "translate-x-0 opacity-100"
            }`}
          >
            <div className="flex relative hover:scale-110 transition-transform duration-500 group justify-center cursor-pointer items-center flex-col">
              <div
                onClick={fetchAvatar}
                className="absolute -top-0.5 z-10 -right-1.5 bg-slate-200 cursor-pointer hover:scale-110 rounded-full"
              >
                <IoReloadCircle
                  className={`text-xl text-secondary scale-125 ${
                    rotate ? "rotate-once" : ""
                  }`}
                />
              </div>
              <div onClick={() => handleProfileClick("guest")}>
                <Avatar
                  className="text-black/50"
                  size="lg"
                  showFallback
                  src={
                    guestChatUsers?.guestProfile
                      ?.toLowerCase()
                      ?.includes("http")
                      ? guestChatUsers?.guestProfile
                      : API_BASE_URL + "/" + guestChatUsers.guestProfile
                  }
                />

                <div className="text-sm bg-red-30 select-none mt-0.5 ml-1 cursor-pointer duration-100 text-center font-medium text-[#2B0282] flex justify-center items-center">
                  Guest
                  <FaAngleRight className="mt-0.5" />
                </div>
              </div>
            </div>

            <div
              className="flex transition-transform hover:scale-110 duration-500 group justify-center cursor-pointer items-center flex-col"
              onClick={() => handleProfileClick("login")}
            >
              <Avatar
                className="text-black/50"
                size="lg"
                showFallback
                src="https://images.unsplash.com/broken"
              />
              <div className="text-sm mt-0.5 ml-1 select-none cursor-pointer group-hover:tracking-wide duration-100 text-center font-medium text-[#2B0282] flex justify-center items-center">
                Login
                <FaAngleRight className="mt-0.5" />
              </div>
            </div>
          </div>

          <div
            className={`absolute transition-transform duration-500 flex w-full justify-center items-center flex-col ${
              activePanel === "guest"
                ? "translate-x-0 opacity-100"
                : "translate-x-full opacity-0"
            }`}
          >
            {activePanel === "guest" && (
              <>
                <Input
                  placeholder=""
                  label="Enter Your name"
                  color="primary"
                  size="sm"
                  variant="bordered"
                  className="w-full"
                  value={userName}
                  onChange={(e) => setUser(e.target.value)}
                />
                {error && (
                  <p className="text-xs text-danger text-left ml-2 w-full">
                    {error}
                  </p>
                )}
                <Button
                  radius="sm"
                  variant="ghost"
                  color="primary"
                  endContent={<FaAngleRight className="mt-0.5" />}
                  className="hover:tracking-wider duration-150 w-full mt-2"
                  type="submit"
                >
                  Start Chat
                </Button>
              </>
            )}
          </div>

          <div
            className={`absolute transition-transform duration-500 flex w-full justify-center items-center flex-col ${
              activePanel === "login"
                ? "translate-x-0 opacity-100"
                : "translate-x-full opacity-0"
            }`}
          >
            {activePanel === "login" && (
              <>
                <Login />
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default UserLoginModeForm;
