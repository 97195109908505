import { Link, useLocation } from "react-router-dom";
import React from "react";
import { MdDashboard } from "react-icons/md";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { HiAnnotation } from "react-icons/hi";
import { FaFacebookMessenger, FaUser } from "react-icons/fa";

import "./css/SideNav.css";
const SideNav = (props) => {
  const { hamClick } = props;
  const loc = useLocation().pathname;

  return (
    <div
      className={`h-screen z-50 duration-100 fixed top-[55px] shadow dark:shadow-slate-600 ${
        !hamClick ? "left-0 w-[180px]" : "-left-[0px] w-[50px]"
      }`}
    >
      <div className="w-full h-full sidebar dark:!bg-[#121212] !z-50 !bg-white">
        <div className="center">
          <ul
            className={`!mb-2  ${
              hamClick ? "!pt-5 flex gap-2 flex-col" : "!pt-0"
            }`}
          >
            <p
              className={`${hamClick ? "hidden" : "block"} title tracking-wide`}
            >
              MAIN
            </p>
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc === "/dashboard"
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff]`
                    : ""
                }
              >
                <MdDashboard className="icon scale-110" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Dashboard
                </span>
              </li>
            </Link>
            <Link to="/dashboard/users" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc.includes("/dashboard/users")
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff] mt-1`
                    : " mt-1"
                }
              >
                <FaUser className="icon" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Users
                </span>
              </li>
            </Link>
            <Link to="/dashboard/feedback" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc.includes("/dashboard/feedback")
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff] mt-1`
                    : " mt-1"
                }
              >
                <HiAnnotation className="icon scale-110" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Feedback
                </span>
              </li>
            </Link>
            <p
              className={`${
                hamClick ? "hidden" : "block"
              } title tracking-wide uppercase`}
            >
              Interactives
            </p>
            <Link
              to="/dashboard/addchatcontent"
              style={{ textDecoration: "none" }}
            >
              <li
                className={
                  loc.includes("/dashboard/addchatcontent")
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff] mt-1`
                    : " mt-1"
                }
              >
                <BiSolidMessageSquareAdd className="icon scale-110" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Chat content
                </span>
              </li>
            </Link>

            <Link to="/dashboard/message" style={{ textDecoration: "none" }}>
              <li
                className={
                  loc.includes("/dashboard/message")
                    ? `dark:!bg-[#363636]  !bg-[#dcd4ff] mt-1`
                    : " mt-1"
                }
              >
                <FaFacebookMessenger className="icon scale-95" />
                <span className={`${hamClick ? "hidden" : "block"}`}>
                  Message
                </span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
