import React, { useContext, useEffect, useState } from "react";
import { Avatar, Badge } from "@nextui-org/react";
import ChatContent from "./ChatContent";
import { useLocation } from "react-router-dom";
import { API_BASE_URL } from "../../../redux/config";
import ChatContext from "../../../chatContext/ChatContext";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";

const ChatProfile = () => {
  const [chatProfileClick, setChatProfileClick] = useState(false);
  const { renderData, setRenderData } = useContext(ChatContext);
  const location = useLocation().pathname;
  const [logo, setLogo] = useState("");
  const [unreadMsg, setUnreadMsg] = useState(0);

  useEffect(() => {
    const chatUserId = localStorage.getItem("adminid");
    const userId = localStorage.getItem("userid");

    if (chatUserId && userId) {
      const data = { chatUserId, userId };

      fetch(`${API_BASE_URL}/api/messages/oneunread`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => setUnreadMsg(data?.unreadmsg || 0))
        .catch((error) => console.error("Error fetching chat data:", error));
    }
  }, [renderData]);

  const fetchlogoImages = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/images/roundlogo`);
      if (!response.ok) {
        throw new Error("Failed to fetch images");
      }
      const imagePath = await response.json();
      setLogo(imagePath);
    } catch (error) {
      setLogo("");
    }
  };
  useEffect(() => {
    fetchlogoImages();
  }, []);

  const updateMessagesAsRead = async (senderId, receiverId) => {
    try {
      await axios.put(
        `${API_BASE_URL}/api/messages/updatemsg/${senderId}/${receiverId}`,
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setRenderData((p) => !p);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  const profileHandelClick = () => {
    const receiverId = localStorage.getItem("adminid");
    const senderId = localStorage.getItem("userid");
    if (receiverId && senderId) {
      updateMessagesAsRead(senderId, receiverId);
    }
    setChatProfileClick(true);
  };

  const [crossClick, setCrossClick] = useState(false);

  return (
    <div>
      {!chatProfileClick ? (
        <div
          className={`fixed bottom-20 z-[1000000000000000000000000000000000] right-16 ${
            location.includes("dashboard") ? "hidden" : "block"
          }`}
        >
          <div className="relative">
            <div className={` ${crossClick ? "hidden" : "block"}`}>
              <div className="absolute right-[0px] top-[-110px] flex items-end gap-3">
                <div
                  onClick={() => setCrossClick((p) => !p)}
                  className={`h-[40px] flex justify-center items-center w-[40px] rounded-full hover:bg-slate-100 bg-white/70 shadow border cursor-pointer group `}
                >
                  <RxCross2 className="text-2xl text-black/60 group-hover:text-black" />
                </div>
                <div className="bg-white text-black w-[250px] shadow-md rounded-3xl p-3 px-4">
                  Have any questions? we are here to help!😊
                </div>
              </div>
            </div>
          </div>
          <Badge
            content={unreadMsg > 9 ? "9+" : unreadMsg}
            className={`mt-1 ml-1 ${
              unreadMsg < 1
                ? "opacity-0 select-none"
                : "opacity-100 select-text"
            }`}
            color="primary"
            placement="top-left"
          >
            <Avatar
              onClick={profileHandelClick}
              isBordered
              size="lg"
              color="primary z-[100000000000000000000000000000000000000000000000000000] "
              src={API_BASE_URL + logo}
              className="cursor-pointer !object-contain"
            />
          </Badge>
        </div>
      ) : (
        <ChatContent
          chatProfile={API_BASE_URL + logo}
          setChatProfileClick={setChatProfileClick}
        />
      )}
    </div>
  );
};

export default ChatProfile;
