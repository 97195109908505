import React from "react";
import { Input, Button, Card } from "@nextui-org/react";
import { EyeFilledIcon } from "../../../../common/assets/jsx/EyeFilledIcon.js";
import { MailIcon } from "../../../../common/assets/jsx/MailIcon.js";
import { EyeSlashFilledIcon } from "../../../../common/assets/jsx/EyeSlashFilledIcon.js";
import { IoArrowForwardOutline } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import BredCrumbFun from "../../../common/Navigation/BredCrumb.js";

const Form = ({
  toggleVisibility,
  formRegisterData,
  handelRegister,
  formInputFieldChange,
  isVisible,
  errors,
  setFormRegisterData,
  loadingBtn,
}) => {
  const handleClear = () => {
    setFormRegisterData({
      email: "",
      password: "",
      name: "",
      image: "",
    });
  };
  return (
    <>
      <form className="w-[100%]">
        <Card
          radius="none"
          className="rounded-sm flex gap-5 flex-col p-8 font-poppins !shadow-none"
        >
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-[var(--text-dark-color)] font-semibold tracking-wider text-2xl font-Orbitron">
                Add User
              </h3>
              <p className="text-sm text-gray-600 mt-2">
                Add your credentials to create an account and access our chat
                feature.
              </p>
            </div>
            {/* <Image src={Logo} height={50} width={130} alt="logo" /> */}
          </div>
          <BredCrumbFun
            category={"users"}
            categoryLink={"/dashboard/users"}
            subcategory={"add user"}
          />
          <div className="mb-1"></div>

          <div className="flex flex-col gap-6 -mt-1">
            <div className="flex gap-5">
              <div className="flex gap-1 flex-col w-1/2">
                <label
                  htmlFor="name"
                  className="text-sm text-[var(--text-dark-color)] cursor-pointer"
                >
                  Name
                </label>
                <Input
                  radius="sm"
                  size="sm"
                  variant="faded"
                  // isRequired
                  label="Name"
                  type="name"
                  name="name"
                  value={formRegisterData.name}
                  onChange={formInputFieldChange}
                  endContent={
                    <FaUser className="text-xl mb-1 scale-90 text-default-400 pointer-events-none flex-shrink-0" />
                  }
                />
                {errors?.name?.length > 1 && (
                  <div className="text-xs -mt-[0.1rem] -mb-2 font-poppins text-red-700 ml-2">
                    {errors.name}
                  </div>
                )}
              </div>
              <div className="flex gap-1 flex-col w-1/2">
                <label
                  htmlFor="email"
                  className="text-sm  text-[var(--text-dark-color)] cursor-pointer"
                >
                  Email
                </label>
                <Input
                  radius="sm"
                  size="sm"
                  variant="faded"
                  // isRequired
                  label="Email"
                  name="email"
                  value={formRegisterData.email}
                  onChange={formInputFieldChange}
                  type="email"
                  endContent={
                    <MailIcon className="text-2xl mb-1 scale-90 text-default-400 pointer-events-none flex-shrink-0" />
                  }
                />
                {errors?.email?.length > 1 && (
                  <div className="text-xs -mt-[0.1rem] -mb-2 font-poppins text-red-700 ml-2">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>

            <div className="flex gap-5">
              <div className="flex gap-1 flex-col w-1/2 ">
                <div className="flex justify-between">
                  <label
                    htmlFor="email"
                    className="text-sm text-[var(--text-dark-color)] cursor-pointer"
                  >
                    Password
                  </label>
                </div>
                <Input
                  label="Password"
                  // isRequired
                  radius="sm"
                  size="sm"
                  variant="faded"
                  name="password"
                  value={formRegisterData.password}
                  onChange={formInputFieldChange}
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                    >
                      {isVisible ? (
                        <EyeSlashFilledIcon className="text-2xl mb-1 scale-90 text-default-400 pointer-events-none" />
                      ) : (
                        <EyeFilledIcon className="text-2xl mb-1 scale-90 text-default-400 pointer-events-none" />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                  className="max-w-full"
                />
                {errors?.password?.length > 1 && (
                  <div className="text-xs -mt-[0.1rem] -mb-2 font-poppins text-red-700 ml-2">
                    {errors.password}
                  </div>
                )}
              </div>
              <div className="flex gap-1 flex-col w-1/2 ">
                <label
                  htmlFor="confirmPassword"
                  className="text-sm text-gray-800"
                >
                  Confirm Password
                </label>
                <Input
                  label="Confirm Password"
                  radius="sm"
                  size="sm"
                  variant="faded"
                  name="confirmPassword"
                  value={formRegisterData.confirmPassword}
                  onChange={formInputFieldChange}
                  type={isVisible ? "text" : "password"}
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                    >
                      {isVisible ? (
                        <EyeSlashFilledIcon className="text-2xl text-gray-400" />
                      ) : (
                        <EyeFilledIcon className="text-2xl text-gray-400" />
                      )}
                    </button>
                  }
                />
                {errors?.confirmPassword && (
                  <div className="text-xs text-red-700 mt-1">
                    {errors.confirmPassword}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-2 justify-end">
            <div className="flex gap-5">
              <Button
                fullWidth
                color="danger"
                radius="none"
                className="rounded !p-5"
                type="reset"
                onClick={handleClear}
                endContent={<IoArrowForwardOutline />}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                color="primary"
                radius="none"
                className="rounded !p-5"
                type="submit"
                onClick={handelRegister}
                endContent={<IoArrowForwardOutline />}
                isLoading={loadingBtn}
              >
                {loadingBtn ? "Signing up, please wait..." : "Sign Up"}
              </Button>
            </div>
          </div>
        </Card>
      </form>
    </>
  );
};

export default Form;
