import { Avatar } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import ChatContext from "../../../../../chatContext/ChatContext";
import { Progress } from "@nextui-org/react";
import { API_BASE_URL } from "../../../../../redux/config";
import axios from "axios";
import UserLoginModeForm from "./userLoginModeForm";

const UserLoginModal = ({ setOpenUserLoginModal }) => {
  const [activePanel, setActivePanel] = useState("profiles"); // 'profiles', 'guest', 'login'
  const [userName, setUser] = useState("");
  const [error, setError] = useState("");
  const [showChatUsers, setShowChatUsers] = useState(false);
  const {
    guestChatUsers,
    setGuestChatUsers,
    setProfileUserData,
    setguestChatData,
    myChat,
    adminData,
    setShowTalkToPerson,
  } = useContext(ChatContext);
  const [progressBar, setProgressBar] = useState(0);
  const [rotate, setRotate] = useState(false);

  const cancleBtnClk = () => {
    setOpenUserLoginModal((p) => !p);
    setProgressBar(0);
  };

  const handleProfileClick = (panel) => {
    setActivePanel(panel);
  };

  const handleBackClick = () => {
    setActivePanel("profiles");
    setError("");
    setUser("");
    setShowChatUsers(false);
  };

  const navigate = useNavigate();

  const userId = Number(localStorage.getItem("userid"));
  const tokenLength = String(localStorage.getItem("token")).length;

  useEffect(() => {
    if (userId > 0 && tokenLength > 2) {
      setShowChatUsers(true);
    } else {
      setShowChatUsers(false);
    }
  }, [userId, tokenLength]);

  const GuestUserPlaced = (e) => {
    e.preventDefault();
    if (userName.length < 2 || userName.length > 20) {
      setError("Name must be 2-20 characters.");
      return;
    }
    setError("");
    let myId = Date.now();
    setGuestChatUsers((prevState) => ({
      ...prevState,
      guestName: userName,
      guestId: myId,
    }));

    setShowChatUsers(true);
  };

  const AdminChatClick = (e) => {
    localStorage.setItem("adminid", e?.id);
    const createGuestUser = async (data) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/guestuser/user`,
          data
        );
        if (response?.data?.token) {
          localStorage.setItem("token", response?.data?.token);
          localStorage.setItem("userid", response?.data?.user?.id);
          localStorage.setItem("username", response?.data?.user?.name);
          localStorage.setItem("adminid", e?.id);
          const showStartChat = {
            id: -999,
            text: "showinfo",
            date: new Date().toISOString(),
            subData: {},
            type: "sent",
          };

          setShowTalkToPerson(false);

          const updatedChat = [...myChat];
          updatedChat.push(showStartChat);

          setguestChatData(updatedChat);
        }
      } catch (error) {
        console.error(
          "Error creating guest user:",
          error.response ? error.response.data : error.message
        );
        localStorage.removeItem("adminid");
      }
    };
    if (Number(guestChatUsers.guestId) > 0) {
      createGuestUser({
        name: guestChatUsers.guestName,
        profile: guestChatUsers.guestProfile,
      });
    }
    setGuestChatUsers((prevState) => ({
      ...prevState,
      adminName: e.name,
      adminId: e.id,
      adminProfile: e.profile,
    }));
    setProfileUserData((prevState) => ({
      ...prevState,
      name: e.name,
      image: e.profile,
      id: e.id,
    }));
  };
  useEffect(() => {
    if (guestChatUsers?.adminId) {
      setTimeout(() => {
        setProgressBar(30);
      }, 200);
      setTimeout(() => {
        setProgressBar(60);
      }, 400);
      setTimeout(() => {
        setProgressBar(80);
      }, 600);
      setTimeout(() => {
        setProgressBar(99);
      }, 1000);
      setTimeout(() => {
        setProgressBar(100);
      }, 1500);
    }
  }, [guestChatUsers]);

  useEffect(() => {
    if (progressBar === 100) {
      cancleBtnClk();
    }
    // eslint-disable-next-line
  }, [progressBar]);

  const fetchAvatar = async () => {
    setRotate(true);
    const res = await fetch(`${API_BASE_URL}/api/guestuser/avatar`);
    const data = await res.json();
    if (data) {
      setGuestChatUsers((prevState) => ({
        ...prevState,
        guestProfile: data,
      }));
    }
    setRotate(false);
  };
  useEffect(() => {
    fetchAvatar();
    // eslint-disable-next-line
  }, []);

  const [filteredAdminData, setFilteredAdminData] = useState([]);
  useEffect(() => {
    if (Number(localStorage.getItem("userid")) > 0) {
      if (adminData.length > 0) {
        const filter = adminData.filter(
          (e) => Number(e.id) !== Number(localStorage.getItem("userid"))
        );
        setFilteredAdminData(filter);
      }
    } else {
      setFilteredAdminData(adminData);
    }
  }, [adminData]);
  return (
    <div
      onClick={cancleBtnClk}
      className="h-full w-full bg-[#000000be] absolute inset-0 z-[900] flex justify-center items-center"
    >
      <div
        className={`relative bg-[#f0f8ff] overflow-hidden ${
          !showChatUsers ? "p-10" : "py-10 px-5"
        }  rounded shadow-lg w-[90%] max-w-md`}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={cancleBtnClk} className="absolute top-3 right-3 ">
          <RxCross2 className="text-xl text-black/70 hover:scale-110 duration-150 cursor-pointer hover:text-black" />
        </div>
        <div className="h-[150px] w-[150px] bg-[#0056a683] rounded-full absolute top-[-100px] left-[-100px]"></div>
        {guestChatUsers?.adminId ? (
          <div>
            <div className="text-[#2B0282] mb-2 text-base">
              Connecting to admin...
            </div>
            <Progress color="primary" size="md" value={progressBar} />
          </div>
        ) : (
          <>
            {!showChatUsers ? (
              <UserLoginModeForm
                activePanel={activePanel}
                GuestUserPlaced={GuestUserPlaced}
                handleBackClick={handleBackClick}
                fetchAvatar={fetchAvatar}
                rotate={rotate}
                handleProfileClick={handleProfileClick}
                guestChatUsers={guestChatUsers}
                API_BASE_URL={API_BASE_URL}
                userName={userName}
                setUser={setUser}
                error={error}
                navigate={navigate}
              />
            ) : (
              <div className="">
                <h2
                  onClick={() => setShowChatUsers(false)}
                  className="font-semibold flex hover:gap-1.5 hover:tracking-wide cursor-pointer duration-100 justify-center mb-3 items-center gap-1 text-left text-lg text-[#0056a6]"
                >
                  <FaArrowLeft /> Select Person
                </h2>
                <div
                  id="scrollableDivDashboard"
                  className="max-h-[270px] overflow-y-auto overflow-x-hidden py-2 px-2 grid gap-y-4 gap-2  grid-cols-[repeat(auto-fit,minmax(55px,1fr))]"
                >
                  {filteredAdminData.map((e) => {
                    return (
                      <div
                        key={e.id}
                        className="flex transition-transform hover:scale-105 duration-75 group justify-center cursor-pointer items-center flex-col"
                        onClick={() => AdminChatClick(e)}
                      >
                        <Avatar
                          className="text-black/50"
                          size="lg"
                          showFallback
                          src={`${API_BASE_URL}/${e.profile}`}
                        />
                        <div className="text-xs capitalize line-clamp-1 mt-0.5 ml-1 cursor-pointer group-hover:tracking-wide duration-100 text-center font-medium text-[#2B0282] flex justify-center items-center">
                          {e.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserLoginModal;
