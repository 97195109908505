import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { IoArrowForwardOutline } from "react-icons/io5";
import { Input, Button } from "@nextui-org/react";
import { EyeFilledIcon } from "../../../../../common/assets/jsx/EyeFilledIcon.js";
import { EyeSlashFilledIcon } from "../../../../../common/assets/jsx/EyeSlashFilledIcon.js";
import { MailIcon } from "../../../../../common/assets/jsx/MailIcon.js";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../../../redux/slices/authSlice.js";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [userFormData, setUserFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);

  const validateForm = () => {
    const { email, password } = userFormData;
    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }

    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (data) => {
    setLoadingBtn(true);
    try {
      const response = await dispatch(loginUser(data));

      if (response.error) {
        if (
          response.error.code &&
          response.error.code.includes("ERR_BAD_REQUEST")
        ) {
          toast.error("Invalid email or password");
        }
        return;
      }

      if (response.payload.token) {
        localStorage.setItem("token", JSON.stringify(response.payload.token));
        localStorage.setItem(
          "userid",
          JSON.stringify(response.payload.user.id)
        );
        toast.success("User logged in successfully.");
        navigate("/"); // Navigate to the home page without page reload
      } else if (response.payload?.error) {
        toast.error(response.payload.error);
      }
    } catch (error) {
      toast.error("Some error occurred, try again later");
    } finally {
      setLoadingBtn(false);
    }
  };

  const formInputFieldChange = (e) => {
    setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleLogin(userFormData);
    }
  };

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <div className="h-full flex justify-center w-full">
      <form onSubmit={formSubmit} className="flex w-full gap-4 flex-col">
        <div className="flex -mt-1 flex-col">
          <Input
            size="sm"
            variant="faded"
            radius="sm"
            label="Email"
            type="text"
            name="email"
            value={userFormData.email}
            onChange={formInputFieldChange}
            endContent={
              <MailIcon className="text-2xl mb-1 text-default-400 pointer-events-none flex-shrink-0" />
            }
          />
          {errors?.email && (
            <div className="text-xs -mb-1 text-start font-poppins text-red-700 ml-2">
              {errors.email}
            </div>
          )}
        </div>
        <div className="flex gap-1 flex-col -mt-1">
          <Input
            size="sm"
            variant="faded"
            label="Password"
            radius="sm"
            name="password"
            value={userFormData.password}
            onChange={formInputFieldChange}
            endContent={
              <button
                className="focus:outline-none"
                type="button"
                onClick={toggleVisibility}
              >
                {isVisible ? (
                  <EyeSlashFilledIcon className="text-2xl mb-1 text-default-400 pointer-events-none" />
                ) : (
                  <EyeFilledIcon className="text-2xl mb-1 text-default-400 pointer-events-none" />
                )}
              </button>
            }
            type={isVisible ? "text" : "password"}
            className="w-full"
          />
          {errors?.password && (
            <div className="text-xs -mb-2 w-full text-start font-poppins text-red-700 ml-2">
              {errors.password}
            </div>
          )}
        </div>
        <div className="flex gap-2 justify-end">
          <Button
            fullWidth
            color="primary"
            radius="none"
            className="rounded"
            type="submit"
            endContent={<IoArrowForwardOutline />}
            isLoading={loadingBtn}
          >
            {loadingBtn ? "Logging in, please wait..." : "Log In"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
