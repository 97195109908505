import React, { useContext, useEffect, useState } from "react";
import EachUser from "./EachUser";
import ChatContext from "../../../../../../chatContext/ChatContext";
// import UserContext from "../../../../../../../userContext/UserContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../../../redux/config";

const AllUser = ({ isdashboard, userFilter, inpData, userDataAll }) => {
  const { onlineUsers, mydata } = useContext(ChatContext);

  const userData = {};
  const [msgUsers, setMsgUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const fetchAdmins = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/messages/lastmessagedetail/admins`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: String(mydata.id) }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const finaldata = await response.json();
      const finalfileter = finaldata.filter(
        (e) => Number(e.chatUserId) !== Number(mydata.id)
      );
      setFilteredUsers(finalfileter || []);
    } catch (error) {
      console.error("Error fetching admins:", error); // Handle errors
    }
  };

  useEffect(() => {
    setMsgUsers(userDataAll);
  }, [userDataAll]);

  useEffect(() => {
    let finalData = msgUsers;

    if (userFilter.includes("admins")) {
      finalData = finalData.filter((e) => Number(e.privilege) > 0);
      fetchAdmins();
    }
    if (inpData.length > 2) {
      finalData = finalData.filter((e) =>
        e?.lastmsg?.toLowerCase()?.includes(inpData.toLowerCase())
      );
    }

    setFilteredUsers(finalData || []);
    // eslint-disable-next-line
  }, [inpData, msgUsers, userFilter]);

  const { id } = useParams();

  const updateMessagesAsRead = async (senderId, receiverId) => {
    try {
      await axios.put(
        `${API_BASE_URL}/api/messages/updatemsg/${senderId}/${receiverId}`,
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  useEffect(() => {
    const senderId = id || localStorage.getItem("adminid");
    const receiverId = localStorage.getItem("userid");
    updateMessagesAsRead(senderId, receiverId);
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className="h-full pb-[149px] w-full overflow-auto mt-1">
      <div id="scrollableDivDashboard" className="h-full  overflow-auto">
        {filteredUsers.length > 0 ? (
          <div className="px-3 flex gap-2 flex-col">
            {filteredUsers
              .sort((a, b) => new Date(b.lastmsgdate) - new Date(a.lastmsgdate))
              .map((e, index) => {
                let isSend = Number(e.senderid) === Number(userData?.data?.id);
                return (
                  <EachUser
                    key={index}
                    data={e}
                    API_BASE_URL={API_BASE_URL}
                    onlineUsers={onlineUsers}
                    isdashboard={isdashboard}
                    setMsgUsers={setMsgUsers}
                    isSend={isSend}
                  />
                );
              })}
          </div>
        ) : (
          <div className="px-3 flex gap-2 flex-col text-center min-h-[100px] justify-center items-center font-poppins text-sm font-semibold font-black/50">
            Users Not Found
          </div>
        )}
      </div>
    </div>
  );
};

export default AllUser;
