import React from "react";
import ChatContentChild from "../../../../../client/components/messageComponent/ChatContent";

const Chat = () => {
  return (
    <div className="w-full mt-[0.35rem] relative h-full inline-block overflow-hidden">
      <div className="absolute h-full w-full top-0">
        <div className="flex justify-between h-full w-full">
          <ChatContentChild
            chatProfile={""}
            setChatProfileClick={""}
            isDashboard={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;
