import React, { useContext, useEffect, useState } from "react";
import TopNav from "./common/navigation/TopNav";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import DashboardPg from "./pages/main/dashboard/Dashboard";
import Addchat from "./pages/main/addchat/Addchat";
import Feedback from "./pages/interactive/feedback/Feedback";
import Message from "./pages/interactive/message.js/Message";
import Users from "./pages/main/users/Users";
import Register from "../client/components/register/Register";
import ChatContext from "../../chatContext/ChatContext";

const Dashboard = () => {
  const [hamClick, setHamClick] = useState(
    JSON.parse(localStorage.getItem("isDashboardHamClose"))
  );
  const { mydata } = useContext(ChatContext);
  const path = useLocation().pathname;
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
    }
    if (path.toLocaleLowerCase().includes("/dashboard")) {
      if (mydata.privilege !== 1 && mydata.privilege !== 2) {
        navigate("/");
      }
    }
  }, [navigate, mydata, path]);
  if (!path.toLocaleLowerCase().includes("/dashboard")) return;
  return (
    <div>
      {/* {!path.toLocaleLowerCase().includes("/dashboard/register") && (
      )} */}
      <TopNav setHamClick={setHamClick} hamClick={hamClick} />
      <div
        className={`${
          hamClick ? "ml-[50px]" : "ml-[180px]"
        } duration-200 py-3 px-5 bg-white !text-black`}
      >
        <Routes>
          <Route element={<DashboardPg />} path="/dashboard" />
          <Route element={<Addchat />} path="/dashboard/addchatcontent" />
          <Route element={<Register />} path="/dashboard/register" />
          <Route element={<Feedback />} path="/dashboard/feedback" />
          <Route element={<Users />} path="/dashboard/users" />
          <Route
            element={<Message hamClick={hamClick} />}
            path="/dashboard/message"
          />
          <Route
            element={<Message hamClick={hamClick} />}
            path="/dashboard/message/uid/:id"
          />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
